<template>
  <Layout class="withdrawal">
    <BHeader v-show="false">
      <el-form
        ref="form"
        :inline="true"
        :model="formData"
      >
        <el-form-item prop="search">
          <el-input
            v-model="formData.gameSourceId"
            clearable
            placeholder="游戏ID"
            @change="formData.gameSourceId = formData.gameSourceId.trim()"
            @keyup.enter.native="handleSearch"
          />
        </el-form-item>
        <el-form-item>
          <el-button
            type="primary"
            @click="handleSearch"
          >
            搜索
          </el-button>
        </el-form-item>
      </el-form>
    </BHeader>

    <div
      v-loading="statisticsLoading"
      class="overview"
    >
      <div class="items">
        <div class="name">
          待处理提现申请数量
        </div>
        <div class="num">
          {{ statisticsInfo.pendingTotalCount | numberFilter }}  <span class="unit">个</span>
        </div>
      </div>
      <div class="items">
        <div class="name">
          待处理提现申请总金额
        </div>
        <div class="num">
          <span class="unit">$</span> {{ statisticsInfo.pendingTotalAmount | numberFilter }}
        </div>
      </div>
      <div class="items">
        <div class="name">
          总提现申请数量
        </div>
        <div class="num">
          {{ statisticsInfo.applyTotalCount | numberFilter }}  <span class="unit">个</span>
        </div>
      </div>
      <div class="items">
        <div class="name">
          累计提现奖励
        </div>
        <div class="num">
          <span class="unit">$</span> {{ statisticsInfo.cumulativeWithdrawAmount | numberFilter }}
        </div>
      </div>
    </div>
    <el-table
      v-loading="listInfo.loading"
      :data="listInfo.list"
      stripe
      style="width: 100%"
      @sort-change="handleSortTable"
    >
      <el-table-column
        label="申请ID"
        prop="withdrawIdStr"
        width="180px"
      />
      <el-table-column
        label="用户名"
        prop="userIdStr"
        width="180px"
      />
      <el-table-column
        label="名字"
        prop="userName"
        width="140px"
      />
      <el-table-column
        label="申请金额"
        prop="amount"
        sortable="custom"
        width="120px"
      />
      <el-table-column
        label="申请时间"
        prop="createTime"
        sortable="custom"
        width="180px"
      />
      <el-table-column
        label="手机号"
        prop="phone"
        width="160px"
      />
      <el-table-column
        label="PayPaly账户"
        prop="paypal"
        width="180px"
      />
      <el-table-column
        label="状态"
        prop="mstatus"
        width="120px"
      >
        <template #default="{row}">
          <template v-if="!row.isEditing">
            {{ statusMap[row.mstatus] || '未知' }}
          </template>
          <template v-else>
            <el-radio-group v-model="row.mstatus">
              <el-radio :label="2">
                处理成功
              </el-radio>
              <el-radio :label="3">
                处理失败
              </el-radio>
            </el-radio-group>
          </template>
        </template>
      </el-table-column>
      <el-table-column
        label="操作"
        prop="id"
        width="180px"
        fixed="right"
      >
        <template v-slot="{row}">
          <el-button
            :loading="row.loading"
            type="text"
            :disabled="!row.isEditing && row.mstatus !== 1"
            @click="handleEditData(row)"
          >
            {{ row.isEditing ? '保存': '设置' }}
          </el-button>
          <el-button
            v-if="row.isEditing"
            type="text"
            style="color: gray;"
            @click="handleCancelEdit(row)"
          >
            取消设置
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <Pagination
      :total="listInfo.total"
      :page.sync="formData.pageNum"
      :limit.sync="formData.pageSize"
      @pagination="queryDataList"
    />
  </Layout>
</template>

<script>
import {
  getAllWithdrawRecord,
  getWithdrawStatistical,
  updateWithdrawStatus
} from '@/api/friends.js'
import { cloneDeep } from 'lodash'

export default {
  filters: {
    numberFilter (num) {
      if (num === undefined || num === null) {
        return '/'
      }
      return num
    }
  },
  data () {
    return {
      formData: {
        pageNum: 1,
        pageSize: 20
      },
      statisticsLoading: false,
      statisticsInfo: {},
      listInfo: {
        list: [],
        total: 0,
        loading: false
      },
      statusMap: {
        1: '待处理',
        2: '处理成功',
        3: '处理失败'
      },
      tempData: {}
    }
  },
  created () {
    this.handleSearch()
    this.queryOrderStatics()
  },
  methods: {
    queryOrderStatics () {
      this.statisticsLoading = true
      getWithdrawStatistical({})
        .then(res => {
          if (res.code === 200) {
            this.statisticsInfo = res.data
          }
        })
        .finally(() => {
          this.statisticsLoading = false
        })
    },
    handleSearch () {
      this.formData.pageNum = 1
      this.queryDataList()
    },
    queryDataList () {
      this.listInfo.loading = true
      getAllWithdrawRecord(this.formData)
        .then(res => {
          if (res.code === 200) {
            this.listInfo.list = res.data.list || []
            this.listInfo.total = res.data.total || 0
          }
        })
        .finally(() => {
          this.listInfo.loading = false
        })
    },
    handleSortTable ({ prop, order }) {
      this.formData.sortKey = prop
      const sortType = order === 'ascending' ? 'asc' : order === 'descending' ? 'desc' : ''
      this.formData.sortType = sortType
      if (sortType) {
        this.queryDataList()
      }
    },
    handleEditData (row) {
      if (row.isEditing) {
        if (row.mstatus === 1) {
          return this.$message.warning('请选择处理状态')
        }
        this.$set(row, 'loading', true)
        updateWithdrawStatus({
          withdrawIdStr: row.withdrawIdStr,
          mstatus: row.mstatus
        })
          .then(res => {
            if (res.code === 200) {
              this.$set(row, 'isEditing', false)
              this.$message.success('修改成功')
            }
          })
          .finally(() => {
            this.$set(row, 'loading', false)
          })
      } else {
        if (row.mstatus !== 1) return false
        this.$set(row, 'isEditing', true)
        this.tempData[row.withdrawIdStr] = cloneDeep(row)
      }
    },
    handleCancelEdit (row) {
      Object.assign(row, this.tempData[row.withdrawIdStr])
      this.$set(row, 'isEditing', false)
    }
  }
}
</script>

<style lang="less" scoped>
    .withdrawal {
      .overview {
      display: flex;
      // padding: 0 0 20px 0;
      min-width: 880px;
      margin-bottom: 10px;
      .items {
        width: 210px;
        height: 100px;
        box-shadow: 0 0 2px 1px rgba(0, 0, 0, .12);
        border-radius: 4px;
        margin-right: 20px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .num {
          font-size: 26px;
          margin-bottom: 10px;
          .unit {
            font-size: 14px;
          }
        }
        .name {
          font-size: 12px;
        }
      }
    }
  }
</style>
